import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  letters = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  constructor() {
  }

  getBezier(C1: any, C2: any, C3: any, C4: any, percent: any) {
    const B1 = (t: any) => {
      return t * t * t;
    };
    const B2 = (t: any) => {
      return 3 * t * t * (1 - t);
    };
    const B3 = (t: any) => {
      return 3 * t * (1 - t) * (1 - t);
    };
    const B4 = (t: any) => {
      return (1 - t) * (1 - t) * (1 - t);
    };

    let pos: any = {};
    pos['x'] =
      C1.x * B1(percent) +
      C2.x * B2(percent) +
      C3.x * B3(percent) +
      C4.x * B4(percent);
    pos['y'] =
      C1.y * B1(percent) +
      C2.y * B2(percent) +
      C3.y * B3(percent) +
      C4.y * B4(percent);
    return pos;
  }

  getPolylinePaths(startingMarker: any, endingMarker: any, resolution: any) {
    let path: any = [];

    // if (startingMarker.lng < endingMarker.lng) {
    //   const temp = startingMarker;
    //   startingMarker = endingMarker;
    //   endingMarker = temp;
    // }

    const length = Math.sqrt(
      (startingMarker.lat - endingMarker.lat) ** 2 +
      (startingMarker.lng - endingMarker.lng) ** 2
    );
    const offset = length / 20;

    // Gets point position at 1/4 length between startingMarker and endingMarker
    const getPointPosition = (startingPoint: number, endingPoint: number) => {
      return (
        startingPoint - ((length / 4) * (startingPoint - endingPoint)) / length
      );
    };

    // Calculates startingPoint and endingPoint vector, normalizes and return it
    const calculateNormalizedVector = (
      startingPoint: google.maps.LatLngLiteral,
      endingPoint: google.maps.LatLngLiteral
    ): google.maps.LatLngLiteral => {
      const vec = {
        lng: startingPoint.lat - endingPoint.lat,
        lat: endingPoint.lng - startingPoint.lng,
      };

      const vecModulus = Math.sqrt(vec.lng ** 2 + vec.lat ** 2);
      return {
        lng: vec.lng / (vecModulus || 1),
        lat: vec.lat / (vecModulus || 1),
      };
    };

    // Point at 1/4 line length from starting marker
    const center1 = {
      lat: getPointPosition(startingMarker.lat, endingMarker.lat),
      lng: getPointPosition(startingMarker.lng, endingMarker.lng),
    };

    // Point at 1/4 line length from ending marker
    const center2 = {
      lat: getPointPosition(endingMarker.lat, startingMarker.lat),
      lng: getPointPosition(endingMarker.lng, startingMarker.lng),
    };

    // Alters center1 offset for specific cases to position it better
    const getOffsetModifier = () => {
      if (startingMarker.lat > endingMarker.lat) {
        return Math.abs(startingMarker.lat - endingMarker.lat) > 105 &&
        length > 60
          ? 3
          : 1;
      } else {
        return Math.abs(startingMarker.lat - endingMarker.lat) > 90 &&
        Math.abs(startingMarker.lng - endingMarker.lng) > 50 &&
        length > 120
          ? 0.33
          : 1;
      }
    };

    // Calculates final center points lat / lng using normalized vectors and offset
    // Math.min on lat positions prevent points from exceeding map bounds
    const offsetModifier = getOffsetModifier();
    const c1NormalizedVec = calculateNormalizedVector(
      startingMarker,
      endingMarker
    );
    center1.lng =
      center1.lng + -offset * (c1NormalizedVec.lng * offsetModifier);
    center1.lat = Math.min(
      center1.lat + -offset * (c1NormalizedVec.lat * offsetModifier),
      85
    );

    const c2NormalizedVec = calculateNormalizedVector(
      startingMarker,
      endingMarker
    );
    center2.lng = center2.lng + -offset * c2NormalizedVec.lng;
    center2.lat = Math.min(center2.lat + -offset * c2NormalizedVec.lat, 85);

    // Calculates line points position
    let points: any = [];
    for (let it = 0; it <= 1; it += resolution) {
      points.push(
        this.getBezier(
          {x: startingMarker.lat, y: startingMarker.lng},
          {x: center1.lat, y: center1.lng},
          {x: center2.lat, y: center2.lng},
          {x: endingMarker.lat, y: endingMarker.lng},
          it
        )
      );
    }

    for (let i = 0; i < points.length; i++) {
      path.push(new google.maps.LatLng(points[i].x, points[i].y));
    }

    const line = new google.maps.Polyline({
      path: path,
      geodesic: false,
      clickable: true,
      draggable: false,
      strokeColor: '#C83A27',
      strokeWeight: 5,
      strokeOpacity: 0.4,
    });

    return line;
  }

  isSafariBrowser() {
    // @ts-ignore
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent) || window.safari !== undefined;
  }

  getUserAgent() {
    // @ts-ignore
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  checkIfAndroid() {
    return this.getUserAgent() == 'Android';
  }
}

export const MODES = {
  FULL: {
    name: 'full',
    startPath: '/',
  },
  EXPLORE: {
    name: 'explore',
    startPath: '/',
  },
  RECORD: {
    name: 'record',
    startPath: '/tell',
  },
};

export const GOOGLE_MAP_ZOOM_LVL_DEFAULT = 4;
export const GOOGLE_MAP_CENTER_DEFAULT = { lat: 36.114647, lng: -115.172813 };
export const GOOGLE_MAP_CENTER_2_DEFAULT = { lat: 38.114647, lng: -98.172813 };
export const GOOGLE_MAP_STYLE_DEFAULT = [
  {
    featureType: 'all',
    elementType: 'all',
    stylers: [
      { saturation: -100 }, // <-- THIS
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape.natural',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];

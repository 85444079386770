import { Injector } from '@angular/core';
import {environment} from "../../environments/environment";
import {MODES} from "./constants";

export class ContextController {
  currentContext: any = null;
  MODES: any = MODES;

  constructor(private injectorObj: Injector) {
    this.currentContext = environment.currentContext;
  }
}

import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  requestCount = 0;

  constructor(private router: Router, private loaderService: LoaderService) {}

  addToken(request: HttpRequest<any>): HttpRequest<any> {
    const req = request.clone({});
    let headers = req.headers;
    return req.clone({ headers });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.indexOf('https://maps.googleapis.com/maps/api') != -1) {
      next.handle(req);
    }
    ++this.requestCount;
    this.loaderService.show();

    return next.handle(this.addToken(req)).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            --this.requestCount;
            if (this.requestCount <= 0) {
              this.loaderService.hide();
            }
          }
        },
        (err: any) => {
          --this.requestCount;
          if (this.requestCount <= 0) {
            this.loaderService.hide();
          }
        }
      )
    );
  }
}

import {
  AfterViewInit,
  Component, Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {ContextController} from "../../services/context-controller"

declare var WE: any;
declare var earth: any;

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent extends ContextController implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  goToPage() {
    if (this.currentContext === this.MODES.FULL || this.currentContext === this.MODES.EXPLORE) {
      window.location.href = `/stories?view=tile`;
    }
    else if (this.currentContext === this.MODES.RECORD) {
      window.location.href = `/tell`;
    }
  }

  ngOnDestroy() {
  }
}

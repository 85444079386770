import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartComponent } from "./pages/start/start.component";

const routes: Routes = [
  {
    path: '',
    component: StartComponent,
  },
  {
    path: 'stories',
    loadChildren: () => import('./stories/stories.module').then(m => m.StoriesModule)
  },
  {
    path: 'tell',
    loadChildren: () => import('./tell/tell.module').then(m => m.TellModule)
  },
  // {
  //   path: 'intro',
  //   component: IntroComponent,
  // },
  // {
  //   path: 'story',
  //   component: StoryComponent,
  // },
  // {
  //   path: 'story/text',
  //   component: StoryTextComponent,
  // },
  // {
  //   path: 'story/voice',
  //   component: StoryVoiceComponent,
  // },
  // {
  //   path: 'story/map',
  //   component: StoryMapComponent,
  // },
  // {
  //   path: 'story/map/pin/:id',
  //   component: MapPinComponent,
  // },
  // {
  //   path: 'story/information',
  //   component: StoryInformationComponent,
  // },
  // {
  //   path: 'story/thanks',
  //   component: ThanksComponent,
  // },
  // {
  //   path: 'start',
  //   component: StartComponent,
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
